import * as React from "react";

import youtube from "../images/yt_logo_rgb_dark.png";
import twitch from "../images/Twitch-Wordmark-White.png";
import discord from "../images/Discord-Logo+Wordmark-White.png";
import instagram from "../images/glyph-logo_May2016.png";
import twitter from "../images/Twitter_Logo_WhiteOnImage.png";
import banner from "../images/brand/Twitch-Banner.png";

const links = [
  {
    text: "Twitch",
    url: "https://www.twitch.tv/xantictv",
    description:
      "Follow me streaming. Counter Strike, PUBG and Escape from Tarkov.",
    color: "#E95800",
    icon: twitch,
  },
  {
    text: "YouTube",
    url: "https://www.youtube.com/channel/UCQ3YrluUewOLvP38z6rQzZA",
    description: "Uploading my VODs and more here.",
    color: "#1099A8",
    icon: youtube,
  },
  {
    text: "Discord",
    url: "https://discord.gg/DawxAm6",
    description: "Join me for some games here.",
    color: "#BC027F",
    icon: discord,
  },
  {
    text: "Twitter",
    url: "https://twitter.com/xantictv",
    description: "My Twitter account.",
    color: "#0D96F2",
    icon: twitter,
  },
  {
    text: "Instagram",
    url: "https://www.instagram.com/xantictv/",
    description: "My Instagram account.",
    color: "#0D96F2",
    icon: instagram,
  },
];

// markup
const IndexPage = () => {
  return (
    <main
      className="box-border h-screen flex flex-col justify-center items-center"
      style={{ backgroundColor: "#122162" }}
    >
      <title>xantic.tv</title>
      <h1 className="text-white font-bold">xantic.tv</h1>
      <img src={banner} />
      <ul className="flex flex-col md:flex-row justify-center space-bw">
        {links.map((link) => (
          <li
            className="bg-indigo-800 text-white font-bold rounded-lg border shadow-lg m-5 flex-1 h-full"
            key={link.text}
          >
            <a href={link.url} className="block p-10">
              {link.icon && (
                <img
                  src={link.icon}
                  alt={link.text}
                  className="mb-3 max-h-11"
                />
              )}
              {!link.icon && <h2>{link.text}</h2>}
              <p>{link.description}</p>
            </a>
          </li>
        ))}
      </ul>
    </main>
  );
};

export default IndexPage;
